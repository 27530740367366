/* eslint-disable */
import Vue from 'vue'
import VueEvents from 'vue-events'
import VueApexCharts from 'vue-apexcharts'
import wysiwyg from "vue-wysiwyg";
import VueTheMask from 'vue-the-mask'
import cookie from 'js-cookie'
import vuetify from '@/plugins/vuetify'
import jsSHA from "jssha"
import 'vuetify/dist/vuetify.min.css'
import "vue-wysiwyg/dist/vueWysiwyg.css";
import ApexCharts from 'apexcharts';

Vue.use(VueApexCharts);
Vue.use(VueEvents);
Vue.use(wysiwyg, {
    hideModules: { "image": true },
    maxHeight: "500px",


}); // config is optional. more below
Vue.use(VueTheMask);

require('./library/Calendar');

// import slim from 'jquery-slim';

// global.jQuery = slim;

/** import dependencies **/
import router from './router'
import { store } from './store'

// const eventsHub = new Vue();
//60000 is 1 minute
// Vue.use(IdleVue, {eventEmitter: eventsHub, idleTime: 600000, store});

/** import library **/
import rest from '@/library/Rest'
/** Rest (Axios) API */
import storage from '@/library/Storage'
/** Web Storage API */
import auth from '@/library/Auth'
/** Authentication API */
import encryption from '@/library/Encryption'
/** AddressHelper API */
import AddressHelper from "./library/AddressHelper";
/** AddressHelper API */
import Permissions from "./library/Permissions";
//**Local Storage */
import LocalStorageHelper from './library/LocalStorageHelper';
/**
 * Nav Factory Class responsible to Show or Hide Nav Menu Items with bulk functions.
 */
import NavFactory from "./library/NavFactory";

/** Authentication API */


Vue.prototype.$rest = rest();
Vue.prototype.$storage = storage;
Vue.prototype.$auth = auth;
Vue.prototype.$encryption = encryption;
// Vue.prototype.$slim = slim;
Vue.prototype.$address = new AddressHelper();
Vue.prototype.$permissions = new Permissions();
Vue.prototype.$navFactory = new NavFactory();
Vue.prototype.$localStorage = new LocalStorageHelper();
String.prototype.capitalizeFirstLetter = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.wordLength = function(word,world_length) {
    if(word == '' || word == null){
        return word
    }
    if(word.length <= world_length){
        return word
    }
    let text = word.substring(0, world_length);
    return text +'...'
};
Number.prototype.pad = function() {
    return this.toString().padStart(2, "0");
};

String.prototype.compareDate = function(date1){
    if(date1){
        var d1 = new Date(date1);
        var today_date = new Date();;    
        if(d1 > today_date){
            return true;
        }else{
            return false;
        }
    }else{
        return false;
    }
}

/**
 * Format to given currency
 * @param  {String} format       Currency Format.
 * @param  {Number} decimalCount Total no of decimal points.
 * @param  {String} decimal      Decimal symbol.
 * @param  {String} thousands    Thousand separator.
 * @return {[type]}              String
 */
Number.prototype.currency = function(format = "Rs.", decimalCount = 2, decimal = ".", thousands = ",") {
    let amount = this;
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return format + " " + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        return 0;
    }
};

Number.prototype.nepaliCurrency = function(format = "Rs.", decimalCount = 2, decimal = ".", thousands = ",") {
    let amount = this;
    const negativeSign = amount < 0 ? "-" : "";
    amount=amount.toString();
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
   
    let lastThree = amount.substring(amount.length-3);
    let otherNumbers = amount.substring(0,amount.length-3);
    if(otherNumbers != ''){
        lastThree = ',' + lastThree;
    }
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let res = format + " " + negativeSign + otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    return res;
};


Number.prototype.numberFormat = function(format = "", decimalCount = 2, decimal = ".", thousands = ",") {
    let amount = this;
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return format + " " + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        return 0;
    }
};
String.prototype.dateForm = function() {
    if (this.length === 8) return this.substr(0, 4) + '-' + this[4] + this[5] + '-' + this.slice(-2);
    else return this;
};


import App from './App.vue'
import Button from '@/components/Button'
import AddButton from '@/components/AddButton'
import SearchButton from '@/components/SearchButton'
import EditButton from '@/components/EditButton'
import DeleteButton from '@/components/DeleteButton'
import ViewButton from '@/components/ViewButton'
import LoadButton from '@/components/LoadButton'
import RefresButton from '@/components/RefreshButton'
import AlertMessage from '@/components/AlertMessage'
import GradeSection from '@/components/app/GradeSectionFilter'
import PrintButtons from '@/components/PrintButtons'
import VCalendarField from '@/components/VCalendarField'
import NotFound from "./components/svg/NotFound";
import VSubjectField from "./components/app/VSubjectField";
import VGradeField from "./components/app/VGradeField";
import VStudentSearchField from "./components/app/VStudentSearchField";
import VUserSearchField from "./components/app/VUserSearchField";
import VCompanySearchField from "./components/app/VCompanySearchField";
import VBatchField from "./components/app/VBatchField";
import VSectionField from "./components/app/VSectionField";
import DownloadButton from "./components/DownloadButton";
import VUploadedShowcase from "./components/app/VUploadedShowcase";
import VMultiSelectField from "./components/app/VMultiSelectField";
import VSmsInputField from "./components/app/VSmsInputField";
import ViewMoreOrLess from "./components/ViewMoreOrLess";
import VStudentSemiProfile from "./components/app/VStudentSemiProfile";
import IconHeaderSubHeader from "./components/app/IconHeaderSubHeader";
import VImagePreview from "./components/app/VImagePreview";
import VDownloadColumnSelect from "./components/app/VDownloadColumnSelect";
import StrikerComponent from "./components/StrikerComponent";
import VTemplateCreatorField from "./components/app/VTemplateCreatorField";
import VFeeHeadField from "./components/app/VFeeHeadField";
import VSMSStats from "./components/app/VSMSStats";
import Guardian from "./components/Guardian";
import GuardianChildrenList from "./components/GuardianChildrenList";
import MobileScreenHeader from "./components/MobileScreenHeader";
import MobileScreenBox from "./components/MobileScreenBox";
import MobileScreenTransaction from "./components/MobileScreenTransaction";
import MobileScreenInvoiceList from "./components/MobileScreenInvoiceList";
import MobileScreenTransactionMonth from "./components/MobileScreenTransactionMonth";
import MobileScreenFooterBox from "./components/MobileScreenFooterBox";
import MenuItemBoxStatement from "./components/MenuItemBoxStatement";
import MenuItemBoxFees from "./components/MenuItemBoxFees";
import MenuItemBoxCourse from "./components/MenuItemBoxCourse";
import MenuItemBoxResult from "./components/MenuItemBoxResult";
import MenuItemBoxLibrary from "./components/MenuItemBoxLibrary";
import MenuItemBoxTransport from "./components/MenuItemBoxTransport";
import MenuItemBoxGallery from "./components/MenuItemBoxGallery";
import MenuItemBoxNotice from "./components/MenuItemBoxNotice";
import DisplayError from "./components/DisplayError"


/** Charts */
import ImagePicker from './components/ImagePicker';
import AttendanceChart from './components/AttendanceChart';


Vue.component('apexchart', VueApexCharts);
Vue.component('StrikerComponent', StrikerComponent);
Vue.component('VTemplateCreatorField', VTemplateCreatorField);
Vue.component('Button', Button);
Vue.component('AddButton', AddButton);
Vue.component('DownloadButton', DownloadButton);
Vue.component('LoadButton', LoadButton);
Vue.component('SearchButton', SearchButton);
Vue.component('EditButton', EditButton);
Vue.component('DeleteButton', DeleteButton);
Vue.component('AlertMessage', AlertMessage);
Vue.component('ViewButton', ViewButton);
Vue.component('RefreshButton', RefresButton);
Vue.component('GradeSection', GradeSection);
Vue.component('VGradeSectionField', GradeSection);
Vue.component('PrintButtons', PrintButtons);
Vue.component('NotFound', NotFound);
Vue.component('VCalendarField', VCalendarField);
Vue.component('VSubjectField', VSubjectField);
Vue.component('VGradeField', VGradeField);
Vue.component('VSectionField', VSectionField);
Vue.component('VBatchField', VBatchField);
Vue.component('VUploadedShowcase', VUploadedShowcase);
Vue.component('VMultiSelectField', VMultiSelectField);
Vue.component('ViewMoreOrLess', ViewMoreOrLess);
Vue.component('VStudentSearchField', VStudentSearchField);
Vue.component('VCompanySearchField', VCompanySearchField);
Vue.component('VStudentSemiProfile', VStudentSemiProfile);
Vue.component('IconHeaderSubHeader', IconHeaderSubHeader);
Vue.component('VImagePreview', VImagePreview);
Vue.component('VDownloadColumnSelect', VDownloadColumnSelect);
Vue.component('VUserSearchField', VUserSearchField);
Vue.component('VSmsInputField', VSmsInputField);
Vue.component('VFeeHeadField', VFeeHeadField);
Vue.component('VSmsStats', VSMSStats);
Vue.component('GuardianChildrenList', GuardianChildrenList);
Vue.component('MobileScreenHeader', MobileScreenHeader);
Vue.component('MobileScreenBox', MobileScreenBox);
Vue.component('MobileScreenTransaction', MobileScreenTransaction);
Vue.component('MobileScreenInvoiceList', MobileScreenInvoiceList);
Vue.component('MobileScreenTransactionMonth', MobileScreenTransactionMonth);
Vue.component('MobileScreenFooterBox', MobileScreenFooterBox);
Vue.component('MenuItemBoxStatement', MenuItemBoxStatement);
Vue.component('MenuItemBoxFees', MenuItemBoxFees);
Vue.component('MenuItemBoxCourse', MenuItemBoxCourse);
Vue.component('MenuItemBoxResult', MenuItemBoxResult);
Vue.component('MenuItemBoxLibrary', MenuItemBoxLibrary);
Vue.component('MenuItemBoxTransport', MenuItemBoxTransport);
Vue.component('MenuItemBoxGallery', MenuItemBoxGallery);
Vue.component('MenuItemBoxNotice', MenuItemBoxNotice);
Vue.component('DisplayError', DisplayError);



Vue.component('ImagePicker', ImagePicker);
Vue.component('AttendanceChart', AttendanceChart);
Vue.component('Guardian', Guardian);

/** Filters... */
Vue.filter('capitalize', (value) => {
    if (!value) return '';
    return value.toString().charAt(0).toUpperCase() + value.slice(1)
});

Element.prototype.documentOffsetTop = function() {
    return this.offsetTop + (this.offsetParent ? this.offsetParent.documentOffsetTop() : 0);
};

/**
 *
 * @param arr
 * @returns {*[]}
 */
Array.prototype.diff = function(arr, key) {
    return this.filter(function(i) {
        return !(arr.includes(i[key]))
    });
};


String.prototype.cleanHtml = function(length = null) {
    var doc = new DOMParser().parseFromString(this, 'text/html');
    var str = doc.body.textContent || "";
    if (length) {
        return str.length > length ? str.substr(0, length) + '...' : str;
    }
    return str;
};


Vue.config.productionTip = false;
window.app = new Vue({
    vuetify,
    router,
    store,
    render: h => h(App),
    onIdle() {
        // Logout if user is not active
        if (auth.authenticated()) {
            storage.clear();
            cookie.remove('_user');
            cookie.remove('_permissions');
            router.replace({ name: 'login' });
        }
    },
    onActive() {}
}).$mount('#app');