import auth from "@/library/Auth";

function requireAuth(to, from, next) {
  
  if (to.name !== "login") {
    if (to.meta.auth && auth.authenticated() && to.meta.role) {
      next(false);
    } else if (auth.getRole() == "guardian") {
      next("/guardian");
    } else {
      next();
    }
    if (to.meta.auth && auth.authenticated()) {
      if (to.meta.permission && !auth.can(to.meta.permission)) {
        next(false);
      } else {
        if(auth.getRole() != "guardian" && to.name == 'guardian'){
          document.title = to.name ? to.name.toUpperCase() : 'Unknown';
          next("/login");
        }else{
          document.title = to.name ? to.name.toUpperCase() : 'Unknown';
          next();

        }
      }
    } else if (from.name !== "login") {
      document.title = "LOGIN";
      next("/login");
    } else {
      next(false);
    }
  } else {
    if (to.meta.auth && auth.authenticated()) next(false);
    if(!to.meta.auth && auth.authenticated()){
      next('/');
    }
    else {

      document.title = to.name ? to.name.toUpperCase() : 'Unknown';
      next();
    }
  }
  window.scrollTo(0, 0);

}

export default requireAuth;
