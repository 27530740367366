import auth from './Auth'
import ls from './Storage'


export default class NavFactory {
    user = {};

    static isClassTeacher() {
        let user = auth.user();
        let role = ls.get('_role');
        return ((user && user.class_teacher) || role === 'administrator' || role === 'super' || role ==='it-coordinator' || role === 'receptionist' || role === 'reception');
    }

    static isAccountant() {
        let role = ls.get('_role');
        return ((role === 'accountant' || role === 'administrator' || role === 'super') && auth.getServices('ACCOUNT'));
    }

    static isAdministrator(){
        let role = ls.get('_role');
        return (role === 'administrator' || role === 'super')
    }

    static isDev(payload) {
        return (location.host === 'localhost:8080' || location.host === 'eacademy.sahakaryatech.com');
    }

    static isEca() {
        return ([16, 1].includes(auth.company('id')));
    }

    static toSchool() {
        return ([10, 1].includes(auth.company('id')));
    }


    static advanceShowToOnlySchool() {
        return ([1, 28]).includes(auth.company('id'));
    }

   
}
